import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'
import router from './router';
// import jQuery from 'jquery';
import moment from 'moment';
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.use(moment);
// window.swal = swal;
window.moment = moment;
// window.$ = jQuery;

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app');
