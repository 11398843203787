import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      auth_token: null,
      last_auth_token: null,
      user: null
  },
  mutations: {  // syncronous
      auth_token(state, token){
          // localStorage.setItem("auth_token", token);
          state.auth_token = token;
      },
      last_auth_token(state, token){
          // localStorage.setItem("last_auth_token", token);
          state.last_auth_token = token;
      },
      user(state, user){
          // localStorage.setItem("user", user);
          state.user = user
      }
  },
  actions: { // asyncronous
      auth_token(state, token){
          state.commit("auth_token", token);
      },
      last_auth_token(state, token){
          state.commit("last_auth_token", token);
      },
      user(state, user){
          state.commit("user", user);
      }
  },
  getters: {
      auth_token(state){
          return state.auth_token;
      },
      last_auth_token(state){
          return state.last_auth_token;
      },
      user(state){
          return state.user;
      }
  },
  plugins: [new VuexPersistence().plugin]
});
