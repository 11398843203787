<template>
    <header class="topmenu">
        <div class="logo-cont">
            <img src="/images/sauto-red.png" alt="Sauto" height="30">
        </div>

        <div class="menulist">
            <router-link class="menuitem"  to="/tiempo-real">
<!--                <i class="fas fa-crosshairs"></i>-->
                <span>Tiempo Real</span>
            </router-link>
            <router-link class="menuitem" to="/historico">
<!--                <i class="fas fa-route"></i>-->
                <span>Histórico</span>
            </router-link>
            <router-link class="menuitem" to="/alertas">
                <!--                <i class="fas fa-route"></i>-->
                <span>Alertas</span>
            </router-link>
            <router-link class="menuitem" to="/administracion">
                <!--                <i class="fas fa-route"></i>-->
                <span>Administración</span>
            </router-link>
        </div>


        <TopMenuAvatar/>

    </header>
</template>


<script>
    import TopMenuAvatar from "./TopMenuAvatar";

    export default {
        name: 'Login',
        components: {
            TopMenuAvatar
        },
        model: {

        },
        props: {

        },
        data: function(){
            return {
            }
        },
        methods: {
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    header{
        background: #fff;
        padding: 15px 15px;
        box-shadow: 0 0 5px #ccc;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }

    .logo-cont{
        width: 150px;
        text-align: center;
    }

    .menulist{
        display: inline-block;
        position: absolute;
        left: 180px;
        top: 0;

        .menuitem{
            border-left: 1px solid #eee;
            position: relative;
            text-align: center;
            padding: 19px 20px;
            cursor: pointer;
            display: inline-block;
            color: #777;
            text-decoration: none;
            font-size: 14px;
            font-weight: bold;

            i{
                font-size: 30px;
            }

            span{
                display: block;
            }
        }

        .menuitem:hover{
            color: #111;
            background: #f6f6f6;
        }

        .menuitem:last-child{
            border-right: 1px solid #eee;
        }
    }

</style>
