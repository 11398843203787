<template>
  <div id="app">
    <Login v-if="this.$store.getters.user == null"/>

    <TopMenu v-if="this.$store.getters.user != null"/>
    <router-view v-if="this.$store.getters.user != null"></router-view>
  </div>
</template>

<script>
import Login from "./components/Login";
import TopMenu from "./components/TopMenu/TopMenu";

export default {
  name: 'app',
  components: {
    Login,
    TopMenu
  },
  mounted() {
    this.axios.defaults.headers.common['x-auth-token'] = this.$store.getters.auth_token;
  }
}
</script>

<style lang="scss">

    @import "node_modules/bootstrap/scss/bootstrap";
    @import '../node_modules/bootstrap-vue/src/index.scss';
    @import "theme";

    body,html{
        background: #f9f9f9;
        width: 100%;
        height: 100%;
    }

    #app{
        width: 100%;
        height: 100%;
    }

</style>
