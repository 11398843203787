import { render, staticRenderFns } from "./PanelTiempoReal.vue?vue&type=template&id=20a550f8&scoped=true&"
import script from "./PanelTiempoReal.vue?vue&type=script&lang=js&"
export * from "./PanelTiempoReal.vue?vue&type=script&lang=js&"
import style0 from "./PanelTiempoReal.vue?vue&type=style&index=0&id=20a550f8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a550f8",
  null
  
)

export default component.exports