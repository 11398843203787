<template>
    <div class="avatar-cont">
        <div v-on:click="logout">
            <img v-if="this.$store.getters.user.avatar != null" v-bind:src="this.$store.getters.user.avatar">
            <img v-if="this.$store.getters.user.avatar == null" src="/images/avatar.png">
        </div>
    </div>
</template>


<script>
    export default {
        name: 'Login',
        components: {

        },
        model: {

        },
        props: {

        },
        data: function(){
            return {
            }
        },
        methods: {
            logout(){
                this.$store.commit("auth_token", null);
                this.$store.commit("user", null);
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .avatar-cont{
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;

        img{
            width: 40px;
            height: 40px;
            cursor: pointer;
        }
    }

</style>


