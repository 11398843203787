<template xmlns:color="http://www.w3.org/1999/xhtml">
    <section class="panel-administracion">
        <div class="filter-cont bg-light">
                <div class="shadow">
                    <button class="btn btn-danger btn-block justify-center" >
                        Buscar ruta
                    </button>
                </div>
            </div>
    </section>
</template>


<script>

    export default {
        name: 'PanelAdministracion',
        components: {

        },
        model: {

        },
        props: {

        },
        mounted() {
            this.axios.defaults.headers.common['x-auth-token'] = this.$store.getters.auth_token;

        },
        data: function(){
            return {

            }
        },
        methods: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "~leaflet/dist/leaflet.css";

    .panel-administracion{
        margin-top: 60px;
        height: 100%;
    }

    .filter-cont{
        width: 260px;
        height: 100%;
        box-shadow: 0 0 5px #ccc;
        float: left;
        padding-top: 20px;
    }

    .map{
        background: #eee;
        height: 100%;
        margin-left: 200px;
        z-index: 9;
    }
    .startDate{
        width: 155px;
        margin: 0;
    }
    select{
        background-color: white;
        border: 1px solid #ced4da;
    }
    #startDate{
        background-color: white;
        border: 1px solid #ced4da;
        input{
            background: white;
        }
    }
    label{
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        font-weight: bold;
    }

    .calendario-cont{
        width: 134px;
        background-color: #ffffff;
    }
    .input-group{
        background: white;
    }

    span{
        float: left;
        margin-top: 5px;
    }


</style>

