<template xmlns:color="http://www.w3.org/1999/xhtml">
    <section class="panel-historico">
        <div class="filter-cont bg-light">
            <form>
                <div class="form-group col">
                    <div class="text-lg-left">
                        <label id="titulo">Vehículo</label>
                    </div>
                    <select v-model="selectedTracker" class="form-control" v-on:change="onSelectTracker">
                        <option v-for="(tracker, index) in trackers" :key="index" :value="tracker">{{tracker.name}}</option>
                    </select>
                </div>
                <div class="container">
                    <div class="text-lg-left-left color:#FF3">
                        <label>Fecha de inicio</label>
                    </div>
                    <div style="height: 40px;">
                        <div class="mx-auto float-left calendario-cont">
                            <datepicker v-model="fechaInicio" type="date" mx-auto class="form-control-md"
                                        :bootstrap-styling="true"
                                        :disabledDates="state.disabledDates"
                                        style="background: white"
                                        id="startDate" format="dd/MM/yyyy" :language="es">
                            </datepicker>
                        </div>
                        <div class="mx-auto float-left">
                            <select  v-model="horaInicio" class="form-control" id="SelectTimeH">
                                <option value="00">00</option>
                            <option v-for="h in 23" :key="h" :value="h">{{pad(h)}}</option>-->
                            </select>
                        </div>
                        <span>:</span>
                        <div class="mx-auto float-left">
                            <select v-model="minutoInicio" class="form-control" id="SelectTimeM" style="width: 45px" required>
                                <option value="00">00</option>
                                <option v-for="m in 59" :key="m" :value="m">{{pad(m)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="container" id="fechaFinal">
                    <div class="text-lg-left">
                        <label>Fecha final</label>
                    </div>
                    <div style="height: 40px;">
                        <div class=" mx-auto float-left calendario-cont">
                            <datepicker v-model="fechaFin" type="date" class="form-control-md"
                                        id="endDate"
                                        :disabledDates="state.disabledDates"
                                        :bootstrap-styling="true"
                                        format="dd/MM/yyyy"
                                        :language="es">
                            </datepicker>
                        </div>
                        <div class="mx-auto float-left">
                            <select v-model="horaFin" class="form-control" id="SelectTimeH2" style="width: 45px">
                                <option value="00">00</option>
                                <option v-for="H in 23" :key="H" :value="H">{{pad(H)}}</option>
                            </select>
                        </div>
                        <span>:</span>
                        <div class="mx-auto float-left">
                            <select v-model="minutoFin" class="form-control" id="SelectTimeM2 " style="width: 45px">
                                <option value="00">00</option>
                                <option v-for="M in 59" :key="M" :value="M">{{pad(M)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <div class="container">
                <loading :active.sync="isLoading"
                         :can-cancel="false"
                         :is-full-page="fullPage">
                </loading>
                <div class="shadow" v-show="trackers">
                    <button class="btn btn-danger btn-block justify-center" v-on:click="buscar">
                       Buscar ruta
                    </button>
                </div>
            </div>
        </div>
        <div ref="map" class="map"></div>
    </section>
</template>


<script>

    import L from "leaflet/dist/leaflet";
    import Datepicker from 'vuejs-datepicker';
    import { es } from 'vuejs-datepicker/dist/locale';
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    var map;
    var polyline;

    export default {
        name: 'PanelHistorico',
        components: {
            Datepicker,
            Loading
        },
        model: {

        },
        props: {

        },
        mounted() {
            this.axios.defaults.headers.common['x-auth-token'] = this.$store.getters.auth_token;
            this.loadMap();
            this.loadTrackers();

        },
        data: function(){
            return {
                selectedTracker: null,
                trackers: "",
                horaInicio : "00",
                minutoInicio : "00",
                horaFin : "23",
                fechaInicio : new Date(),
                fechaFin : new Date(),
                minutoFin : "59",
                es: es,
                isLoading: false,
                fullPage: true,
                state: {
                    disabledDates: {
                        from: new Date(),
                    }
                }



            }
        },
        methods: {
            pad(value) {
                if (value<=9){
                    return "0"+ value.toString()
                }else {
                    return value.toString()
                }
            },
            //formato de la fecha
            customFormatter(date) {
                return window.moment(date).format('YYYY-MM-DD');
            },
            buscar(){
                this.loadTodayLocations();
            },
            loadMap(){
                map = L.map(this.$refs.map).setView([20.645900,-103.42584], 15);

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(map);
            },
            loadTrackers(){
                let vueComponent = this;
                this.axios.get(process.env.VUE_APP_API_URL + "/client/trackers")
                    .then(function (res) {
                        if(!res.data.error){
                            vueComponent.selectedTracker = res.data.trackers[0];
                            vueComponent.trackers = res.data.trackers;
                        }else{
                            vueComponent.$swal.fire({
                                title: '',
                                text: res.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                        })
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        vueComponent.$swal.fire({
                            title: '',
                            backdrop: false,
                            text: 'Ocurrió un error inesperado',
                            icon: 'error',
                            confirmButtonText: 'Ok'

                        })
                    });
            },
            onSelectTracker(){
                this.loadTodayLocations();
            },
            loadTodayLocations(){
                if(this.selectedTracker == null){
                    return;
                }
                let trackerId = this.selectedTracker.id;
                let startDate = this.customFormatter(this.fechaInicio) + " " + this.horaInicio+":"+this.minutoInicio;
                let endDate = this.customFormatter(this.fechaFin)+ " " + this.horaFin+":"+this.minutoFin;
                this.isLoading = true;
                let url = process.env.VUE_APP_API_URL + "/client/tracker-route?tracker_id=" + trackerId +
                    "&start=" + startDate + "&end=" + endDate;
                let vueComponent = this;
                // cargando
                this.axios.get(url)
                    .then(function (res) {

                        //deja de cargar
                        vueComponent.isLoading=false;
                        if(!res.data.error){
                            vueComponent.drawPolyline(res.data.positions);
                        }else{
                            vueComponent.$swal.fire({
                                title: '',
                                text: res.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            })
                        }
                    })
                    .catch(function (error) {

                        //deja de cargar

                        console.error(error);
                        vueComponent.$swal.fire({
                            title: '',
                            text: 'Ocurrió un error inesperado',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    });
            },
            drawPolyline(positions){
                let style = {
                    "color": "#111",
                    "weight": 3,
                    "opacity": 0.7
                };

                let arrPositions = positions.map(function (item) {
                    return [item[0], item[1]];
                });

                // for(let i in positions){
                //     arrPositions[i] = positions[i].slice(0,2);
                // }

                if(polyline != null){
                    map.removeLayer(polyline);
                }

                if(arrPositions.length === 0){
                     this.$swal.fire({
                         title: '',
                         heightAuto: false,
                         backdrop: true,
                         text: 'No se encontraron rutas',
                         icon: 'info',
                         confirmButtonText: 'Ok'
                     });
                    // console.log("no routes");
                    return;
                }
                polyline = L.polyline(arrPositions, style).addTo(map);
                map.fitBounds(polyline.getBounds());
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    @import "~leaflet/dist/leaflet.css";

    .panel-historico{
        margin-top: 60px;
        height: 100%;
    }

    .filter-cont{
        width: 260px;
        height: 100%;
        box-shadow: 0 0 5px #ccc;
        float: left;
        padding-top: 20px;
    }

    .map{
        background: #eee;
        height: 100%;
        margin-left: 200px;
        z-index: 9;
    }
    .startDate{
        width: 155px;
        margin: 0;
    }
    select{
        background-color: white;
        border: 1px solid #ced4da;
    }
    #startDate{
        background-color: white;
        border: 1px solid #ced4da;
        input{
            background: white;
        }
    }
    label{
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        font-weight: bold;
    }

    .calendario-cont{
        width: 134px;
            background-color: #ffffff;
    }
    .input-group{
        background: white;
    }

    span{
        float: left;
        margin-top: 5px;
    }


</style>


