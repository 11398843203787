<template>
    <section class="tiempo-real">
        <div class="side-cont">

            <div class="filter-cont">
                <input type="text" placeholder="Buscar vehículo" class="form-control" v-model="filter">
            </div>

            <div class="list-trackers">
                <div v-for="(tracker, index) in filteredTrackers" :key="index" class="tracker-menu"
                     v-bind:class="{selected: selectedTracker != null && tracker.id === selectedTracker.id }" v-on:click="selectTracker(tracker)">
                    <img src="/images/ic_car.png" class="image"/>
                    <span class="title">{{tracker.name}}</span>

                    <img src="/images/icons/more_vert-24px.svg" class="more"/>
                    <span class="kmh">0 km/h</span>
                </div>
            </div>

        </div>

        <div ref="map" class="map"></div>

        <div class="tracker-detalle" v-if="selectedTracker">
<!--            <div class="image" style="background-image: url(https://s3-us-west-2.amazonaws.com/assets.izmocars.com/inventory/100296/300230/640x480/20191129_164714.jpg)"></div>-->
            <div class="image" style="background-image: url(/images/ic_car.png)"></div>
<!--            <img src="/images/ic_car.png" class="image"/>-->

            <div class="data">
                <div>{{ selectedTracker.name }}</div>
                <div>0 km/h</div>
                <div><a href="https://www.google.com/maps/place/20.629764,-103.388819" target="_blank">20.629764,-103.388819</a></div>
            </div>
        </div>

    </section>
</template>


<script>
    import L from "leaflet/dist/leaflet";

    var carPinIcon = new L.icon({
        iconUrl: '/images/markers/marker-default.png',
        iconSize: [50, 50],
        iconAnchor:[25,50],
        popupAnchor:  [0, -50]
    });

    var map;

    export default {
        name: 'PanelTiempoReal',
        components: {

        },
        model: {

        },
        props: {

        },
        mounted() {
            this.axios.defaults.headers.common['x-auth-token'] = this.$store.getters.auth_token;
            this.loadMap();
            this.loadTrackers();
        },
        data: function(){
            return {
                filter: "",
                trackers: [],
                selectedTracker: null,
            }
        },
        methods: {
            loadMap(){
                map = L.map(this.$refs.map).setView([20.645900,-103.42584], 15);

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(map);

                let marker = L.marker([20.639352, -103.430426], {icon: carPinIcon}).addTo(map);

                let vueComponent = this;
                marker.on("click", function () {
                    vueComponent.selectTracker(vueComponent.trackers[0]);
                });

            },
            loadTrackers(){
                let vuecomponent = this;
                this.axios.get(process.env.VUE_APP_API_URL + "/client/trackers")
                    .then(function (res) {
                        if(!res.data.error){
                            // let arrTrackers = [];
                            // for(let i=0; i<10; i++) {
                            //     for (let n in res.data.trackers) {
                            //         arrTrackers.push(res.data.trackers[n]);
                            //     }
                            // }
                            // vuecomponent.trackers = arrTrackers;

                            vuecomponent.trackers = res.data.trackers;
                        }else{
                            vuecomponent.$swal("", res.data.error, "warning");
                        }
                    })
                    .catch(function (error) {
                        vuecomponent.$swal("", error, "error");
                    });
            },
            selectTracker(tracker){
                if(this.selectedTracker == null || this.selectedTracker.id !== tracker.id) {
                    this.selectedTracker = tracker;
                    map.panTo(new L.LatLng(20.639352, -103.430426));
                }else{
                    this.selectedTracker = null;
                }
            },
            buscar(){

            },
        },
        computed: {
            filteredTrackers(){
                let filter = this.filter.toLocaleLowerCase();
                return this.trackers.filter(function (tracker) {
                    return tracker.name.toLowerCase().indexOf(filter) !== -1;
                });
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "~leaflet/dist/leaflet.css";

    .tiempo-real{
        padding-top: 60px;
        height: 100%;
    }

    .side-cont{
        width: 300px;
        background: #fff;
        height: 100%;
        box-shadow: 0 0 5px #ccc;
        float: left;
    }

    .filter-cont{

        input {
            height: 45px;
            border-bottom: 1px solid #eee;
            position: relative;
            background-color: #f6f6f6;
            background-image: url(/images/icons/search-24px.svg);
            background-repeat: no-repeat;
            background-position: 10px center;
            padding-left: 40px;
            font-size: 13px;
        }
    }

    .map{
        background: #eee;
        height: 100%;
        margin-left: 200px;
        z-index: 9;
    }

    .list-trackers{
        overflow-y: auto;
        height: calc(100% - 50px);
    }

    .tracker-menu{
        padding: 5px;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        overflow: hidden;

        .image{
            float: left;
            width: 25px;
            margin: 2px 0;
        }

        .title{
            float: left;
            padding: 3px 10px;
            font-size: 12px;
            color: #333;
            overflow: hidden;
            height: 25px;
            width: 190px;
            line-height: 25px;
        }

        .kmh{
            float: right;
            font-size: 11px;
            background: #0c0;
            padding: 2px 4px;
            border-radius: 5px;
            color: #fff;
            margin: 5px 0;
        }

        .kmh.red{
            background: #c00;
        }

        .kmh.yellow{
            background: #cc0;
        }

        .more{
            float: right;
            padding: 3px 0;
            cursor: pointer;
        }
    }

    .tracker-menu:hover{
        background: #f6f6f6;
    }

    .tracker-menu.selected{
        background: #ffe0e4;
    }

    .tracker-detalle{
        width: calc(100% - 320px);
        height: 130px;
        background: #fff;
        position: absolute;
        bottom: 20px;
        left: 310px;
        z-index: 10;
        border-radius: 5px;
        padding: 15px;
        box-shadow: 0px 5px 5px rgba(0,0,0,0.2);

        .image{
            float: left;
            width: 100px;
            height: 100px;
            background-size: cover;
            background-position: 50% 50%;
            border-radius: 50%;
        }
        
        .data{
            float: left;
            width: calc(100% - 115px);
            margin-left: 15px;
        }
    }
</style>


