import Vue from 'vue'
import VueRouter from 'vue-router'
import PanelTiempoReal from './components/Views/PanelTiempoReal.vue'
import PanelHistorico from './components/Views/PanelHistorico.vue'
import PanelAdministracion from './components/Views/PanelAdministracion.vue'

Vue.use(VueRouter);


const routes = [
    { path: '/tiempo-real', component: PanelTiempoReal },
    { path: '/historico', component: PanelHistorico },
    { path: '/administracion', component: PanelAdministracion }
];

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes
});

export default router;
