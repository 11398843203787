<template>
    <section class="login">
        <div id="loginbox">
            <h1 class="login-brand">
                <img src="/images/sauto-red.png" alt="Sauto" height="50">
            </h1>

            <div class="form-group">
                <label>Teléfono</label>

                <div>
                    <ComboCountryCode v-model="countryCode" class="country-code form-control"/>
                    <input type="text" class="telefono form-control" v-model="telefono" maxlength="10"
                           placeholder="Ingresa teléfono a 10 digitos">
                </div>
            </div>
            <div class="form-group">
                <label>Contraseña</label>
                <input type="password" class="form-control" v-model="password" placeholder="Ingresa contraseña">
            </div>
            <div class="form-group">
                <button class="btn btn-primary btn-block" v-on:click="doLogin">Entrar</button>
            </div>
        </div>
    </section>
</template>


<script>
    // import jQuery from "jquery";
    // import swal from "sweetalert2";

    import ComboCountryCode from "./Forms/ComboCountryCode";
    export default {
        name: 'Login',
        components: {
            ComboCountryCode
        },
        model: {

        },
        props: {
            logo: null,
            color: null,
            textColor: null,
        },
        data: function(){
            return {
                countryCode: '+52',
                telefono: '',
                password: ''
            }
        },
        methods: {
            doLogin() {

                let data = {
                    username: this.countryCode + this.telefono,
                    password: this.password
                };

                let vueapp = this;
                this.axios.post(process.env.VUE_APP_API_URL + "/auth", data)
                    .then(function (res) {
                        if(!res.data.error){
                            vueapp.axios.defaults.headers.common['x-auth-token'] = res.data.auth_token;
                            vueapp.$store.commit("auth_token", res.data.auth_token);
                            vueapp.$store.commit("last_auth_token", res.data.auth_token);
                            vueapp.$store.commit("user", res.data.user);
                        }else{
                            vueapp.$swal("", res.data.error, "warning");
                        }
                    })
                    .catch(function (error) {
                        vueapp.$swal("", error, "error");
                    });
            }
        }

    }
</script>

<style scoped lang="scss">

    .country-code{
        width: 140px;
        display: inline-block;
    }

    .telefono{
        width: calc(100% - 140px);
        display: inline-block;
    }

    section.login{
        background-size: cover;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, rgba(255,254,253,.502) 0%, rgba(249,212,215,.502) 32%, rgba(208,231,252,.502) 100%);
    }

    .login-brand{
        text-align: center;
        margin-bottom: 50px;
    }

    #loginbox{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -225px;
        margin-top: -225px;
        width: 450px;
        height: 420px;
        border-radius: 25px;
        padding: 30px;
        background: #fff;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
    }

</style>
